import {
    DOCTOR_RANK_SUCCESS,
    DOCTOR_RANK_PENDING,
    DOCTOR_RANK_FAIL,
    ADD_DOCTOR_RANK_SUCCESS,
    ADD_DOCTOR_RANK_PENDING,
    ADD_DOCTOR_RANK_FAIL,
    UPDATE_DOCTOR_RANK_SUCCESS,
    UPDATE_DOCTOR_RANK_PENDING,
    UPDATE_DOCTOR_RANK_FAIL,
    SET_MESSAGE
} from "../types";
import { toast} from 'react-toastify'; 
import UserService from "../../services/user.service";

//All doctor Ranks

export const getDoctorRanks = (pageNumber, pageSize, searchQuery) => async(dispatch) => {
    dispatch({
        type: DOCTOR_RANK_PENDING,
        payload: { ranks: "isLoading" },
    });
    return await UserService.getDoctorRank(pageNumber, pageSize, searchQuery).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: DOCTOR_RANK_SUCCESS,
                    payload: { ranks: data.data},
                }) :
                dispatch({
                    type: DOCTOR_RANK_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: DOCTOR_RANK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

    //add doctor RANK
export const addDoctorRanks = (body) => async(dispatch) => {
    dispatch({
        type: ADD_DOCTOR_RANK_PENDING,
        payload: { addRanks: "isLoading" },
    });
    return await UserService.addDoctorRank(body).then(
        (data) => {
            data.data.status ? toast.success("Rank added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_DOCTOR_RANK_SUCCESS,
                    payload: { addRanks : data.data.status },
                }) :
                dispatch({
                    type: ADD_DOCTOR_RANK_FAIL,
                    payload: { addRanks : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_DOCTOR_RANK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: ADD_DOCTOR_RANK_FAIL
        })
      });
};

//update doctor rank
export const updateDoctorRanks = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_DOCTOR_RANK_PENDING,
        payload: { updateRanks: "isLoading" },
    });
    return await UserService.updateDoctorRank(body).then(
        (data) => {
            data.data.status ? toast.success("Rank updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_DOCTOR_RANK_SUCCESS,
                    payload: { updateRanks : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_DOCTOR_RANK_FAIL,
                    payload: { updateRanks : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_DOCTOR_RANK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: UPDATE_DOCTOR_RANK_FAIL
        })
      });
}