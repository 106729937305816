export const MODAL_STYLE = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 550,
    height:'auto',
    maxHeight:"600px",
    // overflow:'scroll',
    bgcolor: 'background.paper',
    borderRadius : "5px",
    boxShadow: 24,
    p: 4,
};
export const DATE_FORMAT = 'MMM D, Y'
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URL|| 'http://localhost:44364';
//export const BASE_URL = 'http://localhost:44364';

export const FIREBASE_API_KEY = process.env.REACT_APP_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;